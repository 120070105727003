
import { INGEST_UPLOADS_TYPES } from "@evercam/shared/constants/ingest"
import DroneUploadDialog from "@/components/ingest/uploadDialogs/DroneUploadDialog"
import BIMUploadDialog from "@/components/ingest/uploadDialogs/BIMUploadDialog"
import Upload360Dialog from "@/components/ingest/uploadDialogs/Upload360Dialog"

export default {
  components: {
    DroneUploadDialog,
    BIMUploadDialog,
    Upload360Dialog,
  },
  props: {
    uploadDialog: {
      type: Boolean,
      default: false,
    },
    ingestUploadType: {
      type: String,
      default: "",
    },
    uploadObject: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    ingestUploadTypes: INGEST_UPLOADS_TYPES,
    uploadStarted: true,
  }),
  methods: {
    closeDialog() {
      this.$emit("close-dialog")
    },
    onUploadStarted() {
      this.closeDialog()
      this.uploadStarted = false
    },
  },
}
