
import {
  ALLOWED_DASH_360_TYPES,
  MAX_FILE_SIZE_IN_BYTES,
} from "@evercam/shared/constants/ingest"
import FileBox from "@/components/ingest/FileBox"
import * as tus from "tus-js-client/dist/tus.min.js"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import { useIngestFileUploaderStore } from "@/stores/ingestFileUploader"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

export default {
  components: {
    FileBox,
  },
  data: () => ({
    uploadedUrls: [],
    allowedTypes: ALLOWED_DASH_360_TYPES,
    maxFileSize: MAX_FILE_SIZE_IN_BYTES,
    id: -1,
    currentSelectedFloor: "",
  }),
  computed: {
    ...mapStores(useIngestFileUploaderStore, useAccountStore, useProjectStore),
    areFilesEmpty() {
      return this.ingestFileUploaderStore.files.length === 0
    },
  },
  mounted() {
    this.ingestFileUploaderStore.selectedFileType = "videos"
    this.ingestFileUploaderStore.files = []
  },
  methods: {
    onFilesUpdated({ size }) {
      if (this.ingestFileUploaderStore.files.length >= 0) {
        if (this.ingestFileUploaderStore.files.length > 1) {
          this.$notifications.error({
            text: "Please select one video",
            error: {},
          })
          this.clearForm()

          return
        }
        if (size > this.maxFileSize) {
          this.$notifications.error({
            text: "Max size for a video is 10GB!",
            error: {},
          })
          this.clearForm()

          return
        }
      }
    },
    async processUpload() {
      if (this.areFilesEmpty) {
        this.$notifications.error("Please provide files to upload")

        return
      }

      try {
        const is360LinkGenerated = await this.generate360UploadLink()
        if (!is360LinkGenerated) {
          return
        }
        this.ingestFileUploaderStore.currentUploadStats.isUploading = true
        this.ingestFileUploaderStore.currentUploadStats.percentage = 0
        this.$emit("upload-started")
        for (let i = 0; i < this.ingestFileUploaderStore.files.length; i++) {
          this.uploadedUrls.push(
            await this.uploadFile(this.ingestFileUploaderStore.files[i])
          )
        }
        this.uploadToIngest()
      } catch (e) {
        this.$notifications.error(`Couldn't upload files : ${e}`)
        this.ingestFileUploaderStore.currentUploadStats = null
      }
    },
    async uploadFile(file) {
      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(file, {
          endpoint: `${this.$config.public.portalURL}`,
          chunkSize: Infinity,
          retryDelays: [0, 1000, 3000, 5000],
          metadata: {
            filename: file.name,
            filetype: file.type,
          },
          parallelUploads: 1,
          onProgress: (bytesUploaded) => {
            this.ingestFileUploaderStore.currentUploadStats.percentage = (
              ((this.ingestFileUploaderStore.currentUploadStats.uploadedSize +
                bytesUploaded) /
                this.ingestFileUploaderStore.currentUploadStats.totalSize) *
              100
            ).toFixed(2)
          },
          onSuccess: () => {
            this.ingestFileUploaderStore.currentUploadStats.uploadedSize +=
              file.size
            this.ingestFileUploaderStore.currentUploadStats.percentage = (
              (this.ingestFileUploaderStore.currentUploadStats.uploadedSize /
                this.ingestFileUploaderStore.currentUploadStats.totalSize) *
              100
            ).toFixed(2)

            file.uploadPercentage = "100"
            const [title, fileExtension] = upload.file.name.split(".")
            resolve({
              url: upload.url,
              title,
              fileExtension,
            })
          },
          onError: (error) => {
            console.log("Failed because: " + error)
            reject(error)
          },
        })
        upload.findPreviousUploads().then((previousUploads) => {
          // Found previous uploads so we select the first one.
          if (previousUploads.length) {
            upload.resumeFromPreviousUpload(previousUploads[0])
          }
          upload.start()
        })
      })
    },
    async uploadToIngest() {
      this.ingestFileUploaderStore.currentUploadStats.isProcessing = true
      this.ingestFileUploaderStore.currentUploadStats.isUploading = false
      try {
        await IngestApi.assets360.upload(
          this.id,
          { uploadedBy: this.accountStore.email },
          {
            assetType: "video",
            upload: this.uploadedUrls,
            targetPointsCount: 0,
          }
        )
      } catch (e) {
        this.$notifications.error("Error uploading video", e)
      } finally {
        this.ingestFileUploaderStore.uploadStats = []
        this.ingestFileUploaderStore.currentUploadStats = {
          percentage: 0,
          totalSize: 0,
          uploadedSize: 0,
          isUploading: false,
          isProcessing: false,
        }
        this.uploadedUrls = []

        this.$emit("upload-completed")
      }
    },
    async generate360UploadLink() {
      if (!this.projectStore.selectedProjectExid) {
        return false
      }
      const fileName = this.ingestFileUploaderStore.files[0]?.name

      if (fileName.split("_")[3] !== "00") {
        this.$notifications.error({
          text: "Invalid video, please select 4K.",
        })
        this.clearForm()

        return false
      }

      try {
        const date = this.$moment(fileName.split("_")[1], "YYYYMMDD").format(
          "DD-MM-YYYY"
        )
        const time = this.$moment(fileName.split("_")[2], "HHmmss").format(
          "HH:mm:ss"
        )

        const response = await IngestApi.assets360.createUpload(
          this.projectStore.selectedProjectExid,
          {
            date: date,
            floor: `${this.currentSelectedFloor.replace(
              /[.$/[\]#]/g,
              " "
            )} | ${time}`,
            uploadedBy: this.accountStore.email,
          }
        )

        this.id = response.id

        return true
      } catch (error) {
        this.$notifications.error({
          text: "Could not get token ",
          error,
        })
      } finally {
        this.$analytics.saveEvent("360-GenerateUpload")
      }
    },
    clearForm() {
      this.ingestFileUploaderStore.files = []
      this.currentSelectedFloor = ""
    },
  },
}
